<template>
  <v-form ref="cardForm" class="cardForm">
    <v-row>
      <v-col
        v-if="voucherData.trackingActions.newAddress"
        cols="12"
        style="text-align: left"
      >
        <span style="font-style: italic; font-size: 14px">
          <span style="font-weight: bold">Αίτημα αλλαγής διεύθυνσης σε:</span>
          {{ voucherData.trackingActions.newAddress }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6" style="padding-bottom: 0">
        <VuetifyGoogleAutoComplete
          id="originAddress"
          v-model="currentForm.clientAddress.text"
          outlined
          dense
          classname="form-control"
          color="orange"
          :rules="rules.address"
          label="Διεύθυνση Παράδοσης"
          :hide-details="false"
          country="gr"
          :validate-on-blur="true"
          :disabled="isDisabled"
          hint="Εισαγετε διεύθυνση και επιλέξτε από τις προτεινόμενες"
          @placechanged="getAddressData($event)"
          @blur="resetAddress()"
        >
        </VuetifyGoogleAutoComplete>
      </v-col>
    </v-row>
    <v-row style="margin: 0px" v-if="!isDisabled">
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="$emit('closeForm')"
        class="m-r-10 font-weight-bold"
        style="text-transform: initial"
      >
        Άκυρο
      </v-btn>
      <v-btn
        @click="saveForm"
        color="orange"
        style="text-transform: initial; color: white"
      >
        Αποθήκευση
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import VuetifyGoogleAutoComplete from "vuetify-google-autocomplete";
import { cloneDeep } from "lodash";
import { isValidAddress } from "@/common-js/validations";
import { mapActions } from "vuex";
export default {
  props: {
    voucherData: {
      type: Object,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VuetifyGoogleAutoComplete,
  },
  data() {
    return {
      ignoreBlur: false,
      currentForm: {
        clientAddress: {
          text: null,
          lat: null,
          lon: null,
        },
      },
      rules: {
        address: [
          (v) =>
            isValidAddress(this.currentForm.clientAddress) ||
            "Εισαγετε διεύθυνση και επιλέξτε από τις προτεινόμενες",
        ],
      },
    };
  },
  computed: {},
  methods: {
    ...mapActions({
      changeAddress: "livetracking/livetracking/changeAddress",
    }),
    getAddressData(address) {
      this.ignoreBlur = true;
      let data = {
        text: null,
        lat: null,
        lon: null,
      };
      if (address) {
        data.text = `${address.route} ${
          address.street_number ? address.street_number : ""
        }, ${address.locality} ${address.postal_code}, ${address.country}`;
        data.lat = address.latitude.toString();
        data.lon = address.longitude.toString();
        this.$set(this.currentForm.clientAddress, "lat", data.lat);
        this.$set(this.currentForm.clientAddress, "lon", data.lon);
        this.$set(this.currentForm.clientAddress, "text", data.text);
        if (address.postal_code) {
          let code = Number(address.postal_code.split(" ").join(""));
          this.$set(this.currentForm, "clientPostalCode", code);
        } else {
          this.$set(this.currentForm, "clientPostalCode", null);
        }
      } else {
        // this.$toast.error("Η διεύθυνση δεν βρέθηκε!");
      }
    },
    resetAddress() {
      if (!this.ignoreBlur) {
        this.$set(this.currentForm.clientAddress, "lat", null);
        this.$set(this.currentForm.clientAddress, "lon", null);
      }
      this.ignoreBlur = false;
    },
    saveForm() {
      const isValid = this.$refs.cardForm.validate();
      if (isValid) {
        this.changeAddress({
          vm: this,
          requestBody: {
            id: this.voucherData._id,
            newAddress: {
              text: this.currentForm.clientAddress.text,
              lat: this.currentForm.clientAddress.lat,
              lon: this.currentForm.clientAddress.lon,
              postalCode: this.currentForm.clientPostalCode,
            },
          },
        }).then((res) => {
          this.$emit("closeForm");
        });
      }
    },
  },
  created() {
    this.currentForm = cloneDeep(this.voucherData);
  },
};
</script>

<style lang="scss">
.cardForm {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
